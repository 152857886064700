import {
  Datagrid,
  List,
  TextField,
  useRecordContext,
  ReferenceInput,
  TextInput,
  DateField,
  EditButton,
  ReferenceField,
  Show,
  SimpleShowLayout,
  SelectInput,
  BulkExportButton,
  TopToolbar,
  Button,
  useRefresh,
} from "react-admin";
import CustomItemLabeled from "../../components/fields/CustomItemLabeled";
import CustomCreateForm from "../../components/forms/CustomCreateForm";
import CustomEditForm from "../../components/forms/CustomEditForm";
import Container from "../../components/layouts/Container";
import Item from "../../components/layouts/Item";
import Row from "../../components/layouts/Row";
import dataProvider from "../../api/dataProvider";
import CustomChipField from "../../components/fields/CustomChipField";
import CustomReferenceInput from "../../components/CustomReferenceInput";

const ReportTitle = () => {
  const record = useRecordContext();
  return <span>Report {record ? `"${record.title}"` : ""}</span>;
};

const STATUS_CHOICES = [
  { id: 'OPEN', name:   'OPEN' },
  { id: 'IN_PROGRESS', name:   'IN_PROGRESS' },
  { id: 'ACCEPTED', name:   'ACCEPTED' },
  { id: 'CLOSED', name:   'CLOSED' },
  { id: 'REJECTED', name:   'REJECTED' },
];

const TYPE_CHOICES = [
  { id: 'INFO', name: 'INFO' },
  { id: 'PHOTOS', name: 'PHOTOS' },
  { id: 'RESPECT', name: 'RESPECT' },
  { id: 'INSISTENT', name: 'INSISTENT' },
  { id: 'OFFENSIVE', name: 'OFFENSIVE' },
  { id: 'ABUSE_AND_VIOLENCE', name: 'ABUSE_AND_VIOLENCE' },
  { id: 'PROBLEMS', name: 'PROBLEMS' },
  { id: 'POSITIVE', name: 'POSITIVE' },
  { id: 'RATING', name: 'RATING' },
  { id: 'FOLLOW_UP', name: 'FOLLOW_UP' },
  { id: 'HELP', name: 'HELP' },
  { id: 'OTHER', name: 'OTHER' },
  { id: 'USER_ACCOUNT_DELETED', name: 'USER_ACCOUNT_DELETED' },
  { id: 'USER_ACCOUNT_PAUSED', name: 'USER_ACCOUNT_PAUSED' },
];

const ORIGIN_CHOICES = [
  { id: 'BLOCK', name:   'BLOCK' },
  { id: 'MEEETING_REVIEW', name:   'MEEETING_REVIEW' },
  { id: 'PLAN_REVIEW', name:   'PLAN_REVIEW' },
  { id: 'PLAN_CANCELLED', name:   'PLAN_CANCELLED' },
  { id: 'COORDINATOR_PLAN_REVIEW', name:   'COORDINATOR_PLAN_REVIEW' },
  { id: 'COORDINATOR_PLAN_BLOCK', name:   'COORDINATOR_PLAN_BLOCK' },
  { id: 'PLAN_CHECKLIST', name:   'PLAN_CHECKLIST' },
  { id: 'OTHER', name:   'OTHER' },
];

const NOTIFICATION_TYPE_CHOICES = [
  { id: 'NEW_MATCH', name:   'NEW_MATCH' },
  { id: 'NEW_PLAN', name:   'NEW_PLAN' },
  { id: 'NEW_MEETING', name:   'NEW_MEETING' },
  { id: 'MEETING_REMINDER', name:   'MEETING_REMINDER' },
  { id: 'PLAN_REMINDER', name:   'PLAN_REMINDER' },
  { id: 'MEETING_CANCELLED', name:   'MEETING_CANCELLED' },
  { id: 'PLAN_REVIEW', name:   'PLAN_REVIEW' },
  { id: 'MEETING_REVIEW', name:   'MEETING_REVIEW' },
  { id: 'ABUSE_AND_VIOLENCE', name:   'ABUSE_AND_VIOLENCE' },
  { id: 'RESPECT', name:   'RESPECT' },
  { id: 'USER_BLOCK_X1', name:   'USER_BLOCK_X1' },
  { id: 'USER_BLOCK_X2', name:   'USER_BLOCK_X2' },
  { id: 'USER_BLOCK_X3', name:   'USER_BLOCK_X3' },
  { id: 'IDENTITY_PHOTOS', name:   'IDENTITY_PHOTOS' },
  { id: 'INAPPROPIATE_PHOTOS', name:   'INAPPROPIATE_PHOTOS' },
  { id: 'MISLEADING_PHOTOS', name:   'MISLEADING_PHOTOS' },
  { id: 'PLAN_CANCELLED_A_48', name:   'PLAN_CANCELLED_A_48' },
  { id: 'PLAN_CANCELLED_B_48', name:   'PLAN_CANCELLED_B_48' },
  { id: 'NO_RELATION', name:   'NO_RELATION' },
  { id: 'COORDINATOR_NO_HELP', name:   'COORDINATOR_NO_HELP' },
  { id: 'PLAN_BLOCKED_FRIENDLY', name:   'PLAN_BLOCKED_FRIENDLY' },
  { id: 'PLAN_BLOCKED_INCIDENT', name:   'PLAN_BLOCKED_INCIDENT' },
  { id: 'INFO', name:   'INFO' },
  { id: 'NOT_ATTEND_PLAN', name:   'NOT_ATTEND_PLAN' },
];

const TYPE_COLORS = {
  INFO: {
    label: "INFO",
    color: "info",
  },
  PHOTOS: {
    label: "PHOTOS",
    color: "warning",
  },
  PROBLEMS: {
    label: "PROBLEMS",
    color: "warning",
  },
  USER_ACCOUNT_PAUSED: {
    label: "USER ACCOUNT PAUSED",
    color: "warning",
  },
  USER_ACCOUNT_DELETED: {
    label: "USER ACCOUNT DELETED",
    color: "warning",
  },
  ABUSE_AND_VIOLENCE: {
    label: "ABUSE AND VIOLENCE",
    color: "error",
  },
};

const reportFilters = [
  <SelectInput
    source="status"
    choices={STATUS_CHOICES}
    alwaysOn
  />,
  <SelectInput
    source="type"
    choices={TYPE_CHOICES}
    alwaysOn
  />,
  <SelectInput
    source="origin"
    choices={ORIGIN_CHOICES}
    alwaysOn
  />,
  <SelectInput
    source="notificationType"
    choices={NOTIFICATION_TYPE_CHOICES}
    alwaysOn
  />,
  <CustomReferenceInput
    label="User A"
    source="userA.id"
    reference="users"
    alwaysOn
  />,
  <CustomReferenceInput
    label="User B"
    source="userB.id"
    reference="users"
    alwaysOn
  />,
  <CustomReferenceInput
    label="Plan"
    source="plan.id"
    reference="plans"
    alwaysOn
  />,
];

const ReportShowActions = (props) => {
  const refresh = useRefresh();
  const record = useRecordContext();
  return (
    <TopToolbar>
      <Button
        color="primary"
        onClick={async () => {
          let response = await dataProvider.update("reports", {
            id: record.id,
            data: { status: "REJECTED" },
          });
          refresh();
        }}
      >
        <span>Reject</span>
      </Button>
      {/* Add your custom actions */}
      <Button
        color="primary"
        onClick={async () => {
          let response = await dataProvider.update("reports", {
            id: record.id,
            data: { status: "ACCEPTED" },
          });
          refresh();
        }}
      >
        <span>Accept</span>
      </Button>
    </TopToolbar>
  );
};

export const ReportList = () => {
  const filter = {};
  const sort = { field: "createdAt", order: "DESC" };
  return (
    <List filters={reportFilters} filter={filter} sort={sort}>
      <Datagrid rowClick="show" bulkActionButtons={<BulkExportButton />}>
        <EditButton />
        <CustomChipField
          source="type"
          data={TYPE_COLORS}
        />
        <TextField source="origin" emptyText="-" />
        <TextField source="notificationType" emptyText="-" />
        <TextField source="status" emptyText="-" />
        <ReferenceField
          link="show"
          label="User A"
          source="userA.id"
          reference="users"
          field="userA.username"
        />
        <ReferenceField
          link="show"
          label="Institution"
          source="userA.institution.id"
          reference="institutions"
          emptyText="-"
        />
        <ReferenceField
          link="show"
          label="User B"
          source="userB.id"
          reference="users"
          field="userB.username"
        />
        <ReferenceField
          link="show"
          label="Reporting Institution"
          source="userA.institution.id"
          reference="institutions"
          emptyText="-"
        />
        <ReferenceField
          link="show"
          label="Reported Institution"
          source="userB.institution.id"
          reference="institutions"
          emptyText="-"
        />
        <ReferenceField
          link="show"
          label="Plan"
          source="plan.id"
          reference="plans"
          emptyText="-"
        />
        <ReferenceField
          link="show"
          label="Meeting"
          source="meeting.id"
          reference="meetings"
          emptyText="-"
        />
        <DateField source="createdAt" emptyText="-" />
        {/* <DateField source="updatedAt" emptyText="-" /> */}
      </Datagrid>
    </List>
  );
}

export const ReportEdit = () => {
  return (
    <CustomEditForm title={<ReportTitle />}>
      <Container>
        <Row>
          <Item>
            <TextInput source="title" fullWidth />
          </Item>
        </Row>

        <Row>
          <Item>
            <ReferenceInput
              label="User A"
              source="userA.id"
              reference="users"
              required
              disabled
            />
          </Item>
          <Item>
            <ReferenceInput
              label="User B"
              source="userB.id"
              reference="users"
              disabled
            />
          </Item>
        </Row>

        <Row>
          <Item>
            <ReferenceInput
              label="Plan"
              source="plan.id"
              reference="plans"
              disabled
            />
          </Item>
          <Item>
            <ReferenceInput
              label="Meeting"
              source="meeting.id"
              reference="meetings"
              disabled
            />
          </Item>
        </Row>

        <Row>
          <Item sx={12} sm={12} md={9}>
            <TextInput
              source="description"
              multiline
              rows={4}
              fullWidth
              disabled
            />
          </Item>
        </Row>

        <Row>
          <Item>
            <SelectInput
              source="status"
              choices={STATUS_CHOICES}
              fullWidth
              required
            />
          </Item>
          <Item>
            <SelectInput
              source="type"
              choices={TYPE_CHOICES}
              fullWidth
              required
              disabled
            />
          </Item>
          <Item>
            <SelectInput
              source="origin"
              choices={ORIGIN_CHOICES}
              fullWidth
              required
              disabled
            />
          </Item>
          <Item>
            <SelectInput
              source="notificationType"
              choices={NOTIFICATION_TYPE_CHOICES}
              fullWidth
              required
              disabled
            />
          </Item>
        </Row>
      </Container>
    </CustomEditForm>
  );
};

export const ReportCreate = () => {
  return (
    <CustomCreateForm>
      <Container>
        <Row>
          <Item>
            <TextInput source="title" fullWidth />
          </Item>
        </Row>

        <Row>
          <Item>
            <ReferenceInput
              label="User A"
              source="userA.id"
              reference="users"
              required
            />
          </Item>
          <Item>
            <ReferenceInput
              label="User B"
              source="userB.id"
              reference="users"
            />
          </Item>
        </Row>

        <Row>
          <Item>
            <ReferenceInput label="Plan" source="plan.id" reference="plans" />
          </Item>
          <Item>
            <ReferenceInput
              label="Meeting"
              source="meeting.id"
              reference="meetings"
            />
          </Item>
        </Row>

        <Row>
          <Item sx={12} sm={12} md={9}>
            <TextInput source="description" multiline rows={4} fullWidth />
          </Item>
        </Row>

        <Row>
          <Item>
            <SelectInput
              source="type"
              choices={TYPE_CHOICES}
              fullWidth
              required
            />
          </Item>
          <Item>
            <SelectInput
              source="status"
              choices={STATUS_CHOICES}
              fullWidth
              required
            />
          </Item>
        </Row>
      </Container>
    </CustomCreateForm>
  );
};

export const ReportShow = () => {
  return (
    <Show actions={<ReportShowActions />}>
      <SimpleShowLayout>
        <Container>
          <Row>
            <CustomItemLabeled>
              <TextField source="id" emptyText="-" />
            </CustomItemLabeled>
            <CustomItemLabeled>
              <TextField source="title" emptyText="-" />
            </CustomItemLabeled>
          </Row>
          <CustomItemLabeled>
            <TextField source="description" emptyText="-" />
          </CustomItemLabeled>
          <CustomItemLabeled>
            <TextField source="type" emptyText="-" />
          </CustomItemLabeled>
          <CustomItemLabeled>
            <TextField source="notificationType" emptyText="-" />
          </CustomItemLabeled>
          <CustomItemLabeled>
            <TextField source="status" emptyText="-" />
          </CustomItemLabeled>
          <CustomItemLabeled>
            <ReferenceField
              link="show"
              label="User A"
              source="userA.id"
              reference="users"
              field="userA.username"
            />
          </CustomItemLabeled>

          <CustomItemLabeled>
            <ReferenceField
              link="show"
              label="Institution"
              source="userA.institution.id"
              reference="institutions"
              emptyText="-"
            />
          </CustomItemLabeled>
          <CustomItemLabeled>
            <ReferenceField
              link="show"
              label="User B"
              source="userB.id"
              reference="users"
              field="userB.username"
            />
          </CustomItemLabeled>
          <CustomItemLabeled>
            <ReferenceField
              link="show"
              label="Institution"
              source="userB.institution.id"
              reference="institutions"
              emptyText="-"
            />
          </CustomItemLabeled>
          <CustomItemLabeled>
            <ReferenceField
              link="show"
              label="Plan"
              source="plan.id"
              reference="plans"
              emptyText="-"
            />
          </CustomItemLabeled>
          <CustomItemLabeled>
            <ReferenceField
              link="show"
              label="Meeting"
              source="meeting.id"
              reference="meetings"
              emptyText="-"
            />
          </CustomItemLabeled>
          <CustomItemLabeled>
            <ReferenceField
              link="show"
              label="Meeting review"
              source="meetingReview.id"
              reference="meeting-reviews"
              emptyText="-"
            />
          </CustomItemLabeled>
          <CustomItemLabeled>
            <DateField source="createdAt" emptyText="-" />
          </CustomItemLabeled>
          <CustomItemLabeled>
            <DateField source="updatedAt" emptyText="-" />
          </CustomItemLabeled>
        </Container>
      </SimpleShowLayout>
    </Show>
  );
};
