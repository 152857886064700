import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  DateInput,
  TextInput,
  EditButton,
  DateField,
  NumberField,
  NumberInput,
  ReferenceField,
  SingleFieldList,
  ChipField,
  Show,
  PasswordInput,
  ReferenceInput,
  TabbedShowLayout,
  Tab,
  BooleanField,
  useRecordContext,
  SelectInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  SelectField,
  usePermissions,
  useGetIdentity,
  TopToolbar,
  useShowContext,
  downloadCSV,
} from "react-admin";
import CustomCard from "../../components/cards/CustomCard";
import CustomImageField from "../../components/CustomImageField";
import CustomImageInput from "../../components/CustomImageInput";
import CustomCreateForm from "../../components/forms/CustomCreateForm";
import CustomEditForm from "../../components/forms/CustomEditForm";
import Column from "../../components/layouts/Column";

import Container from "../../components/layouts/Container";
import Item from "../../components/layouts/Item";
import Row from "../../components/layouts/Row";
import CustomNestedList from "../../components/lists/CustomNestedList";
import config from "../../config/config";

import JoinInnerIcon from "@mui/icons-material/JoinInner";
import ReportIcon from "@mui/icons-material/Report";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import CustomItemLabeled from "../../components/fields/CustomItemLabeled";
import * as jsonexport from "jsonexport/dist"
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

const UserTitle = () => {
  const record = useRecordContext();
  return <span>User {record ? `"${record.username}"` : ""}</span>;
};

const userFilters = {
  admin: [
    <TextInput source="q" label="Search" alwaysOn />,
    <ReferenceInput
      label="Institution"
      source="institution.id"
      reference="institutions"
      alwaysOn
    />,
    <SelectInput
      source="role"
      choices={[
        // { id: "super_admin", name: "SUPER_ADMIN" },
        { id: "admin", name: "ADMIN" },
        { id: "institution", name: "INSTITUTION" },
        { id: "coordinator", name: "COORDINATOR" },
        { id: "user", name: "USER" },
      ]}
      alwaysOn
    />,
    <SelectInput
      source="status"
      choices={[
        { id: "PENDING", name: "PENDING"},
        { id: "VALIDATED", name: "VALIDATED"},
        { id: "BANNED", name: "BANNED"},
        { id: "PAUSED", name: "PAUSED"},
        { id: "DELETED", name: "DELETED"},
      ]}
      alwaysOn
    />,
    <SelectInput
      source="accessRequiredInfo"
      choices={[
        { id: "true", name: "TRUE" },
        { id: "false", name: "FALSE" },
      ]}
      alwaysOn
    />,
    <SelectInput
      source="imageRightsConsentAccepted"
      choices={[
        { id: "true", name: "TRUE" },
        { id: "false", name: "FALSE" },
      ]}
      alwaysOn
    />,
    <SelectInput
      source="visibility"
      choices={[
        { id: "true", name: "TRUE" },
        { id: "false", name: "FALSE" },
      ]}
      alwaysOn
    />,
    <SelectInput
      source="enabled"
      choices={[
        { id: "true", name: "TRUE" },
        { id: "false", name: "FALSE" },
      ]}
      alwaysOn
    />,
    <SelectInput
      source="group"
      choices={[
        // { id: "super_admin", name: "SUPER_ADMIN" },
        { id: "1", name: "1" },
        { id: "2", name: "2" },
        { id: "3", name: "3" },
        { id: "4", name: "4" },
      ]}
      alwaysOn
    />,
  ],
  institution: [<TextInput source="q" label="Search" alwaysOn />],
};

const exporter = (records) => {

  const csv = jsonexport(records.map(record=>
    ({
      "id": record.id,
      "name": record.name,
      "surname": record.surname,
      "username": record.username,
      "email": record.email,
      "birthdate": record.birthdate,
      "role": record.role,
      "group": record.group,
      "visibility": record.visibility,
      "enabled": record.enabled,
      "accessRequiredInfo": record.accessRequiredInfo,
      "sexual test isCompleted": record.sexualTest.isCompleted,
      "sexual test anathomyScore": record.sexualTest.anathomyScore,
      "sexual test relationshipsScore": record.sexualTest.relationshipsScore,
      "sexual test preventionScore": record.sexualTest.preventionScore,
      "isLegalGuardian": record.contactPerson.isLegalGuardian,
    })
    )).then(csv=>{
    downloadCSV(csv, 'users');
  });
};


export const UserList = () => {
  const { permissions } = usePermissions();
  const { identity } = useGetIdentity();
  const filter = {};
  const sort = { field: "_id", order: "ASC" };
  if (permissions === "institution") {
    filter.institution = identity.institution;
  }
  return (
    <List filters={userFilters[permissions]} filter={filter} sort={sort} exporter={exporter}>
      <Datagrid rowClick="show">
        {permissions === "admin" ? <EditButton /> : null}
        <TextField source="name" emptyText="-" />
        <TextField source="surname" emptyText="-" />
        <TextField source="username" emptyText="-" />
        <CustomImageField
          source="avatar"
          size="small"
          title="avatar"
          sx={{ borderRadius: 20, width: 40, height: 40 }}
          emptyText="-"
        />
        <EmailField source="email" emptyText="-" />
        <TextField source="phonenumber" emptyText="-" />
        {/*<TextField source="dni" />*/}
        <ReferenceField
          link="show"
          label="Institution"
          source="institution.id"
          reference="institutions"
          emptyText="-"
        />
        <TextField source="role" emptyText="-" />
        <TextField source="city" emptyText="-" />
        <DateField source="birthdate" emptyText="-" />
        {/* <ArrayField source="interests">
        <SingleFieldList>
          <ChipField source="label.es" />
        </SingleFieldList>
      </ArrayField> */}
        <SelectField
          source="gender"
          choices={[
            { id: "M", name: "Male" },
            { id: "F", name: "Female" },
            { id: "NB", name: "Not binary" },
            { id: "CM", name: "Cis men" },
            { id: "CF", name: "Cis women" },
            { id: "TM", name: "Transgender men" },
            { id: "TF", name: "Transgender women" },
            { id: "TSM", name: "Transexual men" },
            { id: "TSF", name: "Transexual women" },
            { id: "FL", name: "Fluid" },
          ]}
          emptyText="-"
        />
        <BooleanField source="imageRightsConsentAccepted" emptyText="-" />
        { permissions === "admin" ? <BooleanField source="accessRequiredInfo" emptyText="-" /> : null }
        { permissions === "admin" ? <BooleanField source="visibility" emptyText="-" /> : null }
        { permissions === "admin" ? <BooleanField source="enabled" emptyText="-" /> : null }
        { permissions === "admin" ?<SelectField
          source="status"
          choices={[
            { id: "PENDING", name: "PENDING"},
            { id: "VALIDATED", name: "VALIDATED"},
            { id: "BANNED", name: "BANNED"},
            { id: "PAUSED", name: "PAUSED"},
            { id: "DELETED", name: "DELETED"},
          ]}
          emptyText="-"
        />: null }
        {/*<TextField source="orientation" />
      <TextField source="bio" />
      <TextField source="workplace" />
      <TextField source="education" />
      <TextField source="formationCenter" />
      <TextField source="genderPreference" />
      <NumberField source="searchRadius" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />*/}
      </Datagrid>
    </List>
  );
};

export const UserEdit = () => {
  const { permissions } = usePermissions();

  return (
    <CustomEditForm title={<UserTitle />}>
      <Container>
        <Column xs={12} sm={12} md={9}>
          <Row>
            <Item>
              <TextInput source="username" fullWidth />
            </Item>
            <Item>
              <TextInput source="email" fullWidth />
            </Item>
            <Item>
              <TextInput source="phonenumber" fullWidth />
            </Item>
            <Item>
              <TextInput source="address" fullWidth />
            </Item>
            <Item>
              <ReferenceInput
                label="Institution"
                source="institution.id"
                reference="institutions"
                fullWidth
              />
            </Item>
            {permissions==='admin'?
              <Item>
                <SelectInput
                  source="role"
                  choices={[
                    // { id: "super_admin", name: "SUPER_ADMIN" },
                    { id: "admin", name: "ADMIN" },
                    { id: "institution", name: "INSTITUTION" },
                    { id: "coordinator", name: "COORDINATOR" },
                    { id: "user", name: "USER" },
                  ]}
                  fullWidth
                />
              </Item>
            :null}
            {permissions==='admin'?
              <Item>
                <SelectInput
                  source="locale"
                  choices={[
                    // { id: "super_admin", name: "SUPER_ADMIN" },
                    { id: "es", name: "ES" },
                    { id: "en", name: "EN" },
                    { id: "ca", name: "CA" },
                  ]}
                  fullWidth
                />
              </Item>
            :null}
          </Row>
          <Row>
            <Item>
              <TextInput source="name" fullWidth />
            </Item>
            <Item>
              <TextInput source="surname" fullWidth />
            </Item>
            <Item>
              <TextInput source="dni" fullWidth />
            </Item>
            <Item>
              <DateInput source="birthdate" fullWidth />
            </Item>
          </Row>
          <Row>
            <Item>
              <SelectInput
                source="gender"
                choices={[
                  { id: "M", name: "Male" },
                  { id: "F", name: "Female" },
                  { id: "NB", name: "Not binary" },
                  { id: "CM", name: "Cis men" },
                  { id: "CF", name: "Cis women" },
                  { id: "TM", name: "Transgender men" },
                  { id: "TF", name: "Transgender women" },
                  { id: "TSM", name: "Transexual men" },
                  { id: "TSF", name: "Transexual women" },
                  { id: "FL", name: "Fluid" },
                ]}
                fullWidth
              />
            </Item>
            {/* <Item>
              <SelectInput
                source="orientation"
                choices={[
                  { id: "HET", name: "Heterosexual" },
                  { id: "HOM", name: "Homosexual" },
                  { id: "BIS", name: "Bisexual" },
                  { id: "AS", name: "Asexual" },
                  { id: "OTHERS", name: "Others" },
                ]}
                fullWidth
              />
            </Item> */}
            <Item>
              <SelectInput
                source="genderPreference"
                choices={[
                  { id: "M", name: "Male" },
                  { id: "F", name: "Female" },
                  { id: "B", name: "Both" },
                ]}
                fullWidth
              />
            </Item>
            <Item>
              <SelectInput
                source="group"
                choices={[
                  { id: "1", name: "1" },
                  { id: "2", name: "2" },
                  { id: "3", name: "3" },
                  { id: "4", name: "4" },
                ]}
                fullWidth
              />
            </Item>
          </Row>
          <Row>
            <Item xs={12} sm={12} md={9}>
              <TextInput multiline source="bio" rows={4} fullWidth />
            </Item>
          </Row>
          <Row>
            <Item>
              <TextInput source="workplace" fullWidth />
            </Item>
            <Item>
              <TextInput source="education" fullWidth />
            </Item>
            <Item>
              <TextInput source="formationCenter" fullWidth />
            </Item>
            <Item>
              <NumberInput source="searchRadius" fullWidth />
            </Item>
          </Row>
          <Row>
            <Item>
              <TextInput source="contactPerson.fullname" fullWidth />
            </Item>
            <Item>
              <TextInput source="contactPerson.email" fullWidth />
            </Item>
            <Item>
              <TextInput source="contactPerson.phonenumber" fullWidth />
            </Item>
            <Item>
              <BooleanInput
                source="contactPerson.isLegalGuardian"
                label="Is legal guardian?"
                fullWidth
              />
            </Item>
          </Row>

          <span>BLOC 1</span>
          <Row>
            <Item xs={12} sm={12} md={9}>
              <TextInput
                source="personalSituation"
                helperText={false}
                rows={4}
                fullWidth
                multiline
              />
            </Item>
          </Row>
          <Row>
            <Item xs={12} sm={12} md={9}>
              <TextInput
                source="health"
                helperText={false}
                rows={4}
                fullWidth
                multiline
              />
            </Item>
          </Row>
          <span>BLOC 2</span>
          <Row>
            <Item xs={12} sm={12} md={9}>
              <TextInput
                source="personalArea"
                helperText={false}
                rows={4}
                fullWidth
                multiline
              />
            </Item>
          </Row>
          <Row>
            <Item xs={12} sm={12} md={9}>
              <TextInput
                source="movilityArea"
                helperText={false}
                rows={4}
                fullWidth
                multiline
              />
            </Item>
          </Row>
          <Row>
            <Item xs={12} sm={12} md={9}>
              <TextInput
                source="economicArea"
                helperText={false}
                rows={4}
                fullWidth
                multiline
              />
            </Item>
          </Row>
          <Row>
            <Item xs={12} sm={12} md={9}>
              <TextInput
                source="socialArea"
                helperText={false}
                minRows={4}
                fullWidth
                multiline
              />
            </Item>
          </Row>
          <Row>
            <Item xs={12} sm={12} md={9}>
              <TextInput
                source="technologyArea"
                helperText={false}
                rows={4}
                fullWidth
                multiline
              />
            </Item>
          </Row>
          {/* <Row>
            <Item xs={12} sm={12} md={12}>
              <ArrayInput source="observations" fullWidth>
                <SimpleFormIterator inline disableReordering fullWidth>
                  <TextInput
                    sx={{ marginY: 2 }}
                    source="text"
                    helperText={false}
                    rows={4}
                    fullWidth
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </Item>
          </Row>
          <Row>
            <Item xs={12} sm={12} md={12}>
              <ArrayInput source="comments" fullWidth>
                <SimpleFormIterator inline disableReordering fullWidth>
                  <TextInput
                    sx={{ marginY: 2 }}
                    source="text"
                    helperText={false}
                    rows={4}
                    fullWidth
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </Item>
          </Row> */}

          <Row>
            <Item>
              <BooleanInput
                source="sexualTest.isCompleted"
                label="Sexual test"
                fullWidth
              />
            </Item>
          </Row>
          <Row>
            <Item>
              <TextInput
                source="sexualTest.anathomyScore"
                label="Anathomy"
                fullWidth
              />
            </Item>
          </Row>
          <Row>
            <Item>
              <TextInput
                source="sexualTest.relationshipsScore"
                label="Relationships"
                fullWidth
              />
            </Item>
          </Row>
          <Row>
            <Item>
              <TextInput
                source="sexualTest.preventionScore"
                label="Prevention"
                fullWidth
              />
            </Item>
          </Row>
          <Row>
            <Item xs={12} sm={12} md={12}>
              <ArrayInput source="warnings" fullWidth>
                <SimpleFormIterator disableReordering fullWidth>
                  <SelectInput
                    source="warning"
                    choices={[{ id: "BAN", name: "BAN" }]}
                    fullWidth
                  />
                  <TextInput
                    sx={{ marginY: 2 }}
                    source="reason"
                    helperText={false}
                    fullWidth
                  />
                  <DateInput source="fromDate" fullWidth />
                  <DateInput source="toDate" fullWidth />
                </SimpleFormIterator>
              </ArrayInput>
            </Item>
          </Row>
          <Row>
            <Item>
              <BooleanInput source="imageRightsConsentAccepted" fullWidth />
            </Item>
          </Row>
          {permissions==='admin'?
          <Row>
            <Item>
              <BooleanInput source="legalTermsAccepted" fullWidth />
            </Item>
            <Item>
              <BooleanInput source="isAutogeneratedPassword" fullWidth />
            </Item>
          </Row> : null }
          {permissions==='admin'?
          <Row>
            <Item>
              <BooleanInput source="accessRequiredInfo" fullWidth />
            </Item>
            <Item>
              <BooleanInput source="visibility" fullWidth />
            </Item>
            <Item>
              <BooleanInput source="enabled" fullWidth />
            </Item>
            <Item>
            <SelectInput
              source="status"
              choices={[
                { id: "PENDING", name: "PENDING"},
                { id: "VALIDATED", name: "VALIDATED"},
                { id: "BANNED", name: "BANNED"},
                { id: "PAUSED", name: "PAUSED"},
                { id: "DELETED", name: "DELETED"},
              ]}
              fullWidth
            />
          </Item>
          </Row> : null }
         
        </Column>

        <Column xs={12} sm={12} md={3}>
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomImageInput
                source="avatar"
                label="Avatar image"
                accept="image/*"
              />
            </Item>
            <Item xs={4} sm={4} md={4}>
              <CustomImageInput
                source="photos.0"
                label="Photos"
                accept="image/*"
              />
            </Item>
            <Item xs={4} sm={4} md={4}>
              <CustomImageInput
                source="photos.1"
                label="Photos"
                accept="image/*"
              />
            </Item>
            <Item xs={4} sm={4} md={4}>
              <CustomImageInput
                source="photos.2"
                label="Photos"
                accept="image/*"
              />
            </Item>
          </Row>
          {/* <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomFileInput
                source="testAvastFile"
                label="Test avast"
                accept="application/pdf"
              />
            </Item>
          </Row> */}
          {/* <Row>
            <Item xs={12} sm={12} md={12}>
              <TextInput source="photos" fullWidth />
            </Item>
          </Row> */}
          <Row>
            <Item xs={12} sm={12} md={12}>
              <ReferenceArrayInput source="interests" reference="interests">
                <SelectArrayInput
                  optionText={`label.${config.defaultLocale}`}
                />
              </ReferenceArrayInput>
            </Item>
          </Row>
        </Column>
      </Container>
    </CustomEditForm>
  );
};

export const UserCreate = () => {
  const { permissions } = usePermissions();

  return (
    <CustomCreateForm>
      <Container>
        <Column xs={12} sm={12} md={9}>
          <Row>
            <Item>
              <TextInput source="username" fullWidth required />
            </Item>
            <Item>
              <PasswordInput
                source="password"
                fullWidth
                defaultValue={Math.random().toString(36).slice(-8)}
                required
              />
            </Item>
          </Row>
          <Row>
            <Item>
              <TextInput source="email" fullWidth required />
            </Item>
            <Item>
              <TextInput source="phonenumber" fullWidth />
            </Item>
            <Item>
              <TextInput source="address" fullWidth />
            </Item>
            {permissions==='admin'?
              <Item>
                <SelectInput
                  source="role"
                  choices={[
                    // { id: "super_admin", name: "SUPER_ADMIN" },
                    { id: "admin", name: "ADMIN" },
                    { id: "institution", name: "INSTITUTION" },
                    { id: "coordinator", name: "COORDINATOR" },
                    { id: "user", name: "USER" },
                  ]}
                  required
                  fullWidth
                />
              </Item>
            :null}
            <Item>
              <ReferenceInput
                label="Institution"
                source="institution.id"
                reference="institutions"
                fullWidth
              />
            </Item>
          </Row>
          <Row>
            <Item>
              <TextInput source="name" fullWidth required />
            </Item>
            <Item>
              <TextInput source="surname" fullWidth />
            </Item>
            <Item>
              <DateInput source="birthdate" fullWidth />
            </Item>
            <Item>
              <TextInput source="dni" fullWidth />
            </Item>
          </Row>

          <Row>
            {/* <Item>
              <SelectInput
                source="gender"
                choices={[
                  { id: "M", name: "Male" },
                  { id: "F", name: "Female" },
                  { id: "NB", name: "Not binary" },
                  { id: "CM", name: "Cis men" },
                  { id: "CF", name: "Cis women" },
                  { id: "TM", name: "Transgender men" },
                  { id: "TF", name: "Transgender women" },
                  { id: "TSM", name: "Transexual men" },
                  { id: "TSF", name: "Transexual women" },
                  { id: "FL", name: "Fluid" },
                ]}
                fullWidth
              />
            </Item> */}
            {/* <Item>
              <SelectInput
                source="orientation"
                choices={[
                  { id: "HET", name: "Heterosexual" },
                  { id: "HOM", name: "Homosexual" },
                  { id: "BIS", name: "Bisexual" },
                  { id: "AS", name: "Asexual" },
                  { id: "OTHERS", name: "Others" },
                ]}
                fullWidth
              />
            </Item> */}
            {/* <Item>
              <SelectInput
                source="genderPreference"
                choices={[
                  { id: "M", name: "Male" },
                  { id: "F", name: "Female" },
                  { id: "B", name: "Both" },
                ]}
                fullWidth
              />
            </Item> */}
            <Item>
              <SelectInput
                source="group"
                choices={[
                  { id: "1", name: "1" },
                  { id: "2", name: "2" },
                  { id: "3", name: "3" },
                  { id: "4", name: "4" },
                ]}
                fullWidth
              />
            </Item>
          </Row>
          <Row>
            <Item xs={12} sm={12} md={9}>
              <TextInput multiline source="bio" rows={4} fullWidth />
            </Item>
          </Row>
          <Row>
            <Item>
              <TextInput source="workplace" fullWidth />
            </Item>
            <Item>
              <TextInput source="education" fullWidth />
            </Item>
            <Item>
              <TextInput source="formationCenter" fullWidth />
            </Item>
            <Item>
              <NumberInput source="searchRadius" fullWidth />
            </Item>
          </Row>
          <Row>
            <Item>
              <TextInput source="contactPerson.fullname" fullWidth />
            </Item>
            <Item>
              <TextInput source="contactPerson.email" fullWidth />
            </Item>
            <Item>
              <TextInput source="contactPerson.phonenumber" fullWidth />
            </Item>
            <Item>
              <BooleanInput
                source="contactPerson.isLegalGuardian"
                label="Is legal guardian?"
                fullWidth
              />
            </Item>
          </Row>

          <span>BLOC 1</span>
          <Row>
            <Item xs={12} sm={12} md={9}>
              <TextInput
                source="personalSituation"
                helperText={false}
                rows={4}
                fullWidth
                multiline
              />
            </Item>
          </Row>
          <Row>
            <Item xs={12} sm={12} md={9}>
              <TextInput
                source="health"
                helperText={false}
                rows={4}
                fullWidth
                multiline
              />
            </Item>
          </Row>
          <span>BLOC 2</span>
          <Row>
            <Item xs={12} sm={12} md={9}>
              <TextInput
                source="personalArea"
                helperText={false}
                rows={4}
                fullWidth
                multiline
              />
            </Item>
          </Row>
          <Row>
            <Item xs={12} sm={12} md={9}>
              <TextInput
                source="movilityArea"
                helperText={false}
                rows={4}
                fullWidth
                multiline
                label="Movility area"
              />
            </Item>
          </Row>
          <Row>
            <Item xs={12} sm={12} md={9}>
              <TextInput
                source="economicArea"
                helperText={false}
                rows={4}
                fullWidth
                multiline
              />
            </Item>
          </Row>
          <Row>
            <Item xs={12} sm={12} md={9}>
              <TextInput
                source="socialArea"
                helperText={false}
                rows={4}
                fullWidth
                multiline
                label="Social area"
              />
            </Item>
          </Row>
          <Row>
            <Item xs={12} sm={12} md={9}>
              <TextInput
                source="technologyArea"
                helperText={false}
                rows={4}
                fullWidth
                multiline
                label="Technology area"
              />
            </Item>
          </Row>
          <Row>
            <Item>
              <BooleanInput
                source="sexualTest.isCompleted"
                label="Sexual test"
                fullWidth
              />
            </Item>
          </Row>
          <Row>
            <Item>
              <TextInput
                source="sexualTest.anathomyScore"
                label="Anathomy"
                fullWidth
              />
            </Item>
          </Row>
          <Row>
            <Item>
              <TextInput
                source="sexualTest.relationshipsScore"
                label="Retlationships"
                fullWidth
              />
            </Item>
          </Row>
          <Row>
            <Item>
              <TextInput
                source="sexualTest.preventionScore"
                label="Prevention"
                fullWidth
              />
            </Item>
          </Row>
          <Row>
            <Item>
              <BooleanInput source="imageRightsConsentAccepted" fullWidth />
            </Item>
          </Row>
          {permissions==='admin'?
          <Row>
            <Item>
              <BooleanInput source="legalTermsAccepted" fullWidth />
            </Item>
            <Item>
              <BooleanInput source="isAutogeneratedPassword" fullWidth />
            </Item>
          </Row> : null }
          {permissions==='admin'?
          <Row>
            <Item>
              <BooleanInput source="accessRequiredInfo" fullWidth />
            </Item>
            <Item>
              <BooleanInput source="visibility" fullWidth />
            </Item>
            <Item>
              <BooleanInput source="enabled" fullWidth />
            </Item>
            <Item>
            <SelectInput
              source="status"
              choices={[
                { id: "PENDING", name: "PENDING"},
                { id: "VALIDATED", name: "VALIDATED"},
                { id: "BANNED", name: "BANNED"},
                { id: "PAUSED", name: "PAUSED"},
                { id: "DELETED", name: "DELETED"},
              ]}
              fullWidth
            />
          </Item>
          </Row> : null }
        </Column>
        <Column xs={12} sm={12} md={3}>
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomImageInput
                source="avatar"
                label="Avatar image"
                accept="image/*"
              />
            </Item>
          </Row>
          {/* <Row>
            <Item xs={12} sm={12} md={12}>
              <TextInput source="photos" fullWidth />
            </Item>
          </Row> */}
          {/* <Row>
            <Item xs={12} sm={12} md={12}>
              <ArrayInput source="interests">
                <SimpleFormIterator>
                  <TextInput source="label.es" />
                  <TextInput source="label.ca" />
                  <TextInput source="label.en" />
                  <TextInput source="value" />
                </SimpleFormIterator>
              </ArrayInput>
            </Item>
          </Row> */}
        </Column>
      </Container>
    </CustomCreateForm>
  );
};

const UserShowActions = () => {
  const { permissions } = usePermissions();
  const { record } = useShowContext();
  let hasPermissions = false;
  if (
    permissions === "admin" ||
    (permissions === "institution" &&
      ["user", "coordinator"].indexOf(record.role) !== -1)
  ) {
    hasPermissions = true;
  }
  return <TopToolbar>
    {hasPermissions ? <EditButton /> : null}
    </TopToolbar>;
};

export const UserShow = () => {

  const { permissions } = usePermissions();
  
  return (
    <Show actions={<UserShowActions />}>
      <TabbedShowLayout>
        <Tab label="summary">
          <Container p={2}>
            <Column xs={12} sm={12} md={9}>
              <Row>
                <Item>
                  <CustomCard
                    link={"4"}
                    source="userA"
                    resource="reports"
                    label="reports"
                    icon={<ReportIcon htmlColor="white" />}
                  />
                </Item>
                <Item>
                  <CustomCard
                    link={"1"}
                    source="user"
                    resource="user-plans"
                    label="plans"
                    icon={<LocalActivityIcon htmlColor="white" />}
                  />
                </Item>
                <Item>
                  <CustomCard
                    link={"2"}
                    source="users"
                    resource="matches"
                    label="matches"
                    icon={<JoinInnerIcon htmlColor="white" />}
                  />
                </Item>
                <Item>
                  <CustomCard
                    link={"3"}
                    source="users"
                    resource="meetings"
                    label="meetings"
                    icon={<SupervisedUserCircleIcon htmlColor="white" />}
                  />
                </Item>
              </Row>
              <Row>
                {/* <Item>
                <Labeled>
                  <TextField source="id" fullWidth />
                </Labeled>
              </Item> */}
                <CustomItemLabeled
                  itemProps={{ sx: { marginBottom: 2 }, md: 9 }}
                >
                  <TextField
                    fontSize={40}
                    fontWeight={"bold"}
                    source="username"
                    fullWidth
                    emptyText="-"
                  />
                </CustomItemLabeled>
                <CustomItemLabeled
                  itemProps={{
                    sx: { marginBottom: 2 },
                    md: 3,
                    //justifyContent: "flex-end",
                    container: true,
                  }}
                >
                  <TextField
                    fontSize={40}
                    fontWeight={"bold"}
                    color={"gray"}
                    source="role"
                    fullWidth
                    emptyText="-"
                  />
                </CustomItemLabeled>
              </Row>
              <Row>
                <CustomItemLabeled>
                  <ReferenceField
                    link="show"
                    label="Institution"
                    source="institution.id"
                    reference="institutions"
                    emptyText="-"
                  />
                </CustomItemLabeled>
              </Row>
              <Row>
                <CustomItemLabeled>
                  <TextField source="role" emptyText="-" />
                </CustomItemLabeled>
                <CustomItemLabeled>
                  <TextField source="locale" emptyText="-" />
                </CustomItemLabeled>
              </Row>
              <Row>
                <CustomItemLabeled>
                  <EmailField source="email" emptyText="-" />
                </CustomItemLabeled>
                <CustomItemLabeled>
                  <TextField source="name" emptyText="-" />
                </CustomItemLabeled>
                <CustomItemLabeled>
                  <TextField source="surname" emptyText="-" />
                </CustomItemLabeled>
                <CustomItemLabeled>
                  <TextField source="dni" emptyText="-" />
                </CustomItemLabeled>
                <CustomItemLabeled>
                  <DateField source="birthdate" fullWidth emptyText="-" />
                </CustomItemLabeled>
                <CustomItemLabeled>
                  <TextField source="phonenumber" emptyText="-" />
                </CustomItemLabeled>
                <CustomItemLabeled>
                  <TextField source="address" emptyText="-" />
                </CustomItemLabeled>
                {/* <CustomItemLabeled>
                  <TextField source="city" emptyText="-" />
                </CustomItemLabeled> */}
              </Row>

              <Row>
                <CustomItemLabeled>
                  <SelectField
                    source="gender"
                    choices={[
                      { id: "M", name: "Male" },
                      { id: "F", name: "Female" },
                      { id: "NB", name: "Not binary" },
                      { id: "CM", name: "Cis men" },
                      { id: "CF", name: "Cis women" },
                      { id: "TM", name: "Transgender men" },
                      { id: "TF", name: "Transgender women" },
                      { id: "TSM", name: "Transexual men" },
                      { id: "TSF", name: "Transexual women" },
                      { id: "FL", name: "Fluid" },
                    ]}
                    emptyText="-"
                  />
                </CustomItemLabeled>

                {/* <Item>
                <CustomItemLabeled>
                  <SelectField
                    source="orientation"
                    choices={[
                      { id: "HET", name: "Heterosexual" },
                      { id: "HOM", name: "Homosexual" },
                      { id: "BIS", name: "Bisexual" },
                      { id: "AS", name: "Asexual" },
                      { id: "OTHERS", name: "Others" },
                    ]}
                    emptyText="-"
                  />
                </CustomItemLabeled>
              </Item> */}

                <CustomItemLabeled>
                  <SelectField
                    source="genderPreference"
                    choices={[
                      { id: "M", name: "Male" },
                      { id: "F", name: "Female" },
                      { id: "B", name: "Both" },
                    ]}
                    emptyText="-"
                  />
                </CustomItemLabeled>
              </Row>
              <Row>
                <CustomItemLabeled>
                  <SelectField
                    source="group"
                    choices={[
                      { id: "1", name: "1" },
                      { id: "2", name: "2" },
                      { id: "3", name: "3" },
                      { id: "4", name: "4" },
                    ]}
                    fullWidth
                  />
                </CustomItemLabeled>
              </Row>
              <Row>
                <CustomItemLabeled>
                  <TextField source="bio" fullWidth emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField source="workplace" emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField source="education" emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField source="formationCenter" emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField source="searchRadius" emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField
                    source="contactPerson.fullname"
                    fullWidth
                    emptyText="-"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField
                    source="contactPerson.email"
                    fullWidth
                    emptyText="-"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField
                    source="contactPerson.phonenumber"
                    fullWidth
                    emptyText="-"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField
                    source="contactPerson.isLegalGuardian"
                    label={"Is legal guardian?"}
                    emptyText="-"
                    fullWidth
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField
                    source="personalSituation"
                    fullWidth
                    emptyText="-"
                  />
                </CustomItemLabeled>
                {/* <CustomItemLabeled>
                  <TextField source="autonomy" fullWidth emptyText="-" />
                </CustomItemLabeled> */}

                {/* <CustomItemLabeled>
                  <TextField source="socialRelations" fullWidth emptyText="-" />
                </CustomItemLabeled> */}

                {/* <CustomItemLabeled>
                  <TextField source="technology" fullWidth emptyText="-" />
                </CustomItemLabeled> */}

                <CustomItemLabeled>
                  <TextField source="health" fullWidth emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField
                    source="personalArea"
                    fullWidth
                    emptyText="-"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField source="movilityArea" fullWidth emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField source="economicArea" fullWidth emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField source="technologyArea" fullWidth emptyText="-" />
                </CustomItemLabeled>

                {/* <CustomItemLabeled>
                  <TextField
                    source="personalSituation"
                    fullWidth
                    emptyText="-"
                  />
                </CustomItemLabeled> */}
              </Row>
              {/* <Row>
              <CustomItemLabeled>
                <ArrayField source="observations" fullWidth>
                  <Datagrid
                    bulkActionButtons={false}
                    sort={{ field: "createdAt", order: "DESC" }}
                    fullWidth
                  >
                    <TextField
                      sx={{ marginY: 2 }}
                      source="text"
                      helperText={false}
                      rows={4}
                      fullWidth
                      emptyText="-"
                    />
                    <DateField source={"createdAt"} emptyText="-" />                   
                  </Datagrid>
                </ArrayField>
              </CustomItemLabeled>
            </Row>
            <Row>
              <CustomItemLabeled>
                <ArrayField source="comments" fullWidth>
                  <Datagrid bulkActionButtons={false}>
                    <TextField
                      sx={{ marginY: 2 }}
                      source="text"
                      helperText={false}
                      rows={4}
                      fullWidth
                    />
                    <DateField source={"createdAt"} />                   
                  </Datagrid>
                </ArrayField>
              </CustomItemLabeled>
            </Row> */}

              <Row>
                {/* <CustomItemLabeled>
                  <BooleanField source="avastTest" />
                </CustomItemLabeled> */}

                <CustomItemLabeled>
                  <BooleanField source="sexualTest.isCompleted" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField source="sexualTest.anathomyScore" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField source="sexualTest.relationshipsScore" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField source="sexualTest.preventionScore" />
                </CustomItemLabeled>
              </Row>
              <Row>
                <CustomItemLabeled>
                  <BooleanField
                    source="imageRightsConsentAccepted"
                    label="imageRightsConsentAccepted"
                  />
                </CustomItemLabeled>
              </Row>
              { permissions==='admin'?
              <Row>
                <CustomItemLabeled>
                  <BooleanField
                    source="legalTermsAccepted"
                    label="legalTermsAccepted"
                  />
                </CustomItemLabeled>
                <CustomItemLabeled>
                  <BooleanField
                    source="legalTermsAccepted"
                    label="legalTermsAccepted"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField
                    source="isAutogeneratedPassword"
                    label="isAutogeneratedPassword"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField
                    source="accessRequiredInfo"
                    label="accessRequiredInfo"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField
                    source="visibility"
                    label="visibility"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField
                    source="enabled"
                    label="enabled"
                  />
                </CustomItemLabeled>
              
                <CustomItemLabeled>
                  <DateField source="createdAt" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <DateField source="updatedAt" />
                </CustomItemLabeled>
              </Row> : null }
            </Column>
            <Column xs={12} sm={12} md={3}>
              <Row>
                <Item xs={12} sm={12} md={12}>
                  <CustomImageField
                    sx={{ width: "100%", marginBottom: 16, borderRadius: 8 }}
                    source="avatar"
                    size="medium"
                    title="avatar"
                  />
                </Item>
                <Item xs={4} sm={4} md={4}>
                  <CustomImageField
                    sx={{ width: "100%", marginBottom: 16, borderRadius: 8 }}
                    source="photos.0"
                    size="medium"
                    title="photos.0"
                  />
                </Item>
                <Item xs={4} sm={4} md={4}>
                  <CustomImageField
                    sx={{ width: "100%", marginBottom: 16, borderRadius: 8 }}
                    source="photos.1"
                    size="medium"
                    title="photos.1"
                  />
                </Item>
                <Item xs={4} sm={4} md={4}>
                  <CustomImageField
                    sx={{ width: "100%", marginBottom: 16, borderRadius: 8 }}
                    source="photos.2"
                    size="medium"
                    title="photos.2"
                  />
                </Item>
              </Row>
              <Row>
                <Item>
                  {/* <ArrayField source="interests">
                  <SingleFieldList linkType="show" resource="interests">
                    <ChipField source={`label.${config.defaultLocale}`} />
                  </SingleFieldList>
                </ArrayField> */}
                  <ReferenceArrayField source="interests" reference="interests">
                    <SingleFieldList linkType="show">
                      <ChipField source={`label.${config.defaultLocale}`} />
                    </SingleFieldList>
                  </ReferenceArrayField>
                </Item>
              </Row>
            </Column>
          </Container>
        </Tab>
        <Tab label="plans">
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomNestedList resource="user-plans" source={"user"}>
                <Datagrid rowClick="show" bulkActionButtons={false}>
                  <ReferenceField
                    link="show"
                    source="plan.id"
                    reference="plans"
                    emptyText="-"
                  />
                  <TextField source="status" emptyText="-" />
                  <TextField source="reason" emptyText="-" />
                  <BooleanField source="description" fullWidth emptyText="-" />
                </Datagrid>
              </CustomNestedList>
            </Item>
          </Row>
        </Tab>
        <Tab label="matches">
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomNestedList resource="matches" source={"users"}>
                <Datagrid rowClick="show" bulkActionButtons={false}>
                  <TextField source="id" />
                  {/* <ArrayField source="users">
                  <SingleFieldList linkType="show" resource="users">
                    <ChipField source={`username`} />
                  </SingleFieldList>
                </ArrayField> */}
                  <ReferenceArrayField source="users" reference="users">
                    <SingleFieldList linkType="show">
                      <ChipField source={`username`} />
                    </SingleFieldList>
                  </ReferenceArrayField>
                  <TextField source="option" emptyText="-" />
                  <TextField source="type" emptyText="-" />
                  <TextField source="status" emptyText="-" />
                  <BooleanField source="description" fullWidth emptyText="-" />
                </Datagrid>
              </CustomNestedList>
            </Item>
          </Row>
        </Tab>
        <Tab label="meetings">
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomNestedList resource="meetings" source={"users"}>
                <Datagrid rowClick="show" bulkActionButtons={false}>
                  <TextField source="id" emptyText="-" />
                  <ReferenceField
                    link="show"
                    source="userA.id"
                    reference="users"
                    emptyText="-"
                  />
                  <ReferenceField
                    link="show"
                    source="userB.id"
                    reference="users"
                    emptyText="-"
                  />
                  <TextField source="status" emptyText="-" />
                  <TextField source="reason" emptyText="-" />
                  <BooleanField source="comment" fullWidth emptyText="-" />
                </Datagrid>
              </CustomNestedList>
            </Item>
          </Row>
        </Tab>
        <Tab label="reports made">
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomNestedList resource="reports" source={"userA"}>
                <Datagrid rowClick="show" bulkActionButtons={false}>
                  <TextField source="id" emptyText="-" />
                  <ReferenceField
                    link="show"
                    source="userA.id"
                    reference="users"
                    emptyText="-"
                  />
                  <ReferenceField
                    link="show"
                    source="userB.id"
                    reference="users"
                    emptyText="-"
                  />
                  <TextField source="option" emptyText="-" />
                  <TextField source="type" emptyText="-" />
                  <TextField source="status" emptyText="-" />
                  <BooleanField source="description" fullWidth emptyText="-" />
                </Datagrid>
              </CustomNestedList>
            </Item>
          </Row>
        </Tab>
        <Tab label="reports received">
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomNestedList resource="reports" source={"userB"}>
                <Datagrid rowClick="show" bulkActionButtons={false}>
                  <TextField source="id" emptyText="-" />
                  <ReferenceField
                    link="show"
                    source="userA.id"
                    reference="users"
                    emptyText="-"
                  />
                  <ReferenceField
                    link="show"
                    source="userB.id"
                    reference="users"
                    emptyText="-"
                  />
                  <TextField source="option" emptyText="-" />
                  <TextField source="type" emptyText="-" />
                  <TextField source="status" emptyText="-" />
                  <BooleanField source="description" fullWidth emptyText="-" />
                </Datagrid>
              </CustomNestedList>
            </Item>
          </Row>
        </Tab>
        {/* <Tab label="notifications received">
        <Row>
          <Item xs={12} sm={12} md={12}>
            <CustomNestedList resource="notifications" source={"receivers"}>
              <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="id" emptyText="-" />
                <TextField source="name" emptyText="-" />
                <TextField source="type" emptyText="-" />
                <ReferenceField
                  link="show"
                  source="meeting.id"
                  reference="meetings"
                  emptyText="-"
                />
                <ReferenceField
                  link="show"
                  source="plan.id"
                  reference="plans"
                  emptyText="-"
                />
                <ReferenceField
                  link="show"
                  source="report.id"
                  reference="reports"
                  emptyText="-"
                />
                <BooleanField source="pushSent" emptyText="-" />
                <DateField source="pushSentDate" emptyText="-" />
              </Datagrid>
            </CustomNestedList>
          </Item>
        </Row>
      </Tab> */}
        <Tab label="meeting reviews">
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomNestedList resource="meeting-reviews" source={"user"}>
                <Datagrid rowClick="show" bulkActionButtons={false}>
                  <TextField source="id" emptyText="-" />
                  <TextField source="answers" emptyText="-" />
                  <ReferenceField
                    link="show"
                    source="meeting.id"
                    reference="meetings"
                    emptyText="-"
                  />
                  {/* <ReferenceField
                    link="show"
                    source="questionnaire.id"
                    reference="questionnaires"
                    emptyText="-"
                  /> */}
                  <ReferenceField
                    link="show"
                    source="report.id"
                    reference="reports"
                    emptyText="-"
                  />
                  <DateField source="createdAt" emptyText="-" />
                </Datagrid>
              </CustomNestedList>
            </Item>
          </Row>
        </Tab>
        <Tab label="plan reviews">
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomNestedList resource="plan-reviews" source={"user"}>
                <Datagrid rowClick="show" bulkActionButtons={false}>
                  <TextField source="id" emptyText="-" />
                  <TextField source="answers" emptyText="-" />
                  <NumberField source="activityRating" emptyText="-" />
                  <NumberField source="coordinatorRating" emptyText="-" />
                  <ReferenceField
                    link="show"
                    source="plan.id"
                    reference="plans"
                    emptyText="-"
                  />
                  {/* <ReferenceField
                    link="show"
                    source="questionnaire.id"
                    reference="questionnaires"
                    emptyText="-"
                  /> */}
                  <DateField source="createdAt" emptyText="-" />
                </Datagrid>
              </CustomNestedList>
            </Item>
          </Row>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
